import { media, merge } from 'glamor'
import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'

import { HUGE_BREAKPOINT, MEDIUM_BREAKPOINT } from '../../theme/breakpoints'
import { BRAND_COLOR_50, NEUTRAL_COLOR_10 } from '../../theme/colors'

export default StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
    marginTop: rem(50),
  },
  form: merge(
    {
      width: '100%',
      position: 'relative',
    },
    media(MEDIUM_BREAKPOINT, {
      backgroundColor: NEUTRAL_COLOR_10,
      width: rem(630),
      margin: 'auto',
      marginBottom: rem(100),
      padding: `${rem(50)} ${rem(80)}`,
      borderRadius: rem(5),
    }),
  ),
  button: {
    backgroundColor: BRAND_COLOR_50,
  },
  image: merge(
    {
      display: 'none',
      position: 'absolute',
      top: 0,
      height: '100%',
    },
    media(HUGE_BREAKPOINT, {
      display: 'inline-block',
    }),
  ),
  imageLeft: {
    left: 0,
    transform: 'translateX(-100%)',
  },
  imageRight: {
    right: 0,
    transform: 'translateX(100%)',
  },
})

/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, before } from 'glamor'
import { rem } from 'polished'
import { grid, breakpoints, colors, fonts } from './../../theme'

const { row, column, offset } = grid
const { LIBRE_FRANKLIN_REGULAR } = fonts
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints
const { NEUTRAL_COLOR_10, NEUTRAL_COLOR_70, DANGER_COLOR_50 } = colors

const styles = StyleSheet.create({
  container: merge(
    {
      ...row(),
      marginTop: rem(12),
      marginBottom: rem(36),
    },
    media(LARGE_BREAKPOINT, {
      alignItems: 'flex-start',
      marginTop: rem(32),
    }),
  ),
  content: merge(
    {
      ...column(1),
    },
    media(LARGE_BREAKPOINT, {
      ...column(5 / 12),
    }),
  ),
  wrapper: merge(
    media(MEDIUM_BREAKPOINT, {
      ...row(),
    }),
    media(LARGE_BREAKPOINT, {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      marginLeft: 0,
    }),
  ),
  text: merge(
    media(MEDIUM_BREAKPOINT, {
      ...column(1 / 2),
    }),
    media(LARGE_BREAKPOINT, {
      width: '100%',
      maxWidth: '100%',
      margin: 0,
    }),
  ),
  title: merge(
    {
      maxWidth: rem(400),
    },
    media(MEDIUM_BREAKPOINT, {
      maxWidth: rem(450),
    }),
    media(LARGE_BREAKPOINT, {
      maxWidth: rem(500),
    }),
  ),
  list: merge(
    {
      marginTop: rem(24),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(28),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(32),
    }),
    media(HUGE_BREAKPOINT, {
      marginTop: rem(36),
    }),
  ),
  form: merge(
    {
      ...column(1),
      marginTop: rem(12),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(36),
      marginBottom: rem(12),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(64),
      ...offset(1 / 12),
      ...column(6 / 12),
    }),
    before(
      media(LARGE_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        marginTop: '-12%',
        marginLeft: '-13%',
        zIndex: -1,
        width: '126%',
        height: '120%',
        minHeight: rem(592),
        backgroundColor: NEUTRAL_COLOR_10,
      }),
    ),
  ),
  image: merge(
    {
      display: 'none',
    },
    media(MEDIUM_BREAKPOINT, {
      display: 'block',
      objectFit: 'contain',
      ...column(1 / 2),
    }),
    media(LARGE_BREAKPOINT, {
      width: '75%',
      maxWidth: '75%',
      marginTop: rem(24),
      marginLeft: 0,
      marginBottom: rem(24),
      marginRight: 0,
    }),
  ),
  group: merge(
    {
      display: 'flex',
      flexDirection: 'column',
    },
    media(MEDIUM_BREAKPOINT, {
      flexDirection: 'row',
    }),
  ),
  input: merge(
    {
      flex: 1,
      marginBottom: rem(10),
    },
    media(MEDIUM_BREAKPOINT, {
      marginBottom: rem(14),
    }),
  ),
  inputWithMargin: merge(
    {},
    media(MEDIUM_BREAKPOINT, {
      marginRight: rem(18),
    }),
  ),
  terms: merge(
    {
      marginTop: rem(12),
      marginBottom: rem(22),
      color: NEUTRAL_COLOR_70,
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(16),
    }),
  ),
  button: merge({
    width: '100%',
  }),
  error: {
    display: 'block',
    marginTop: rem(6),
    marginBottom: rem(6),
    ...LIBRE_FRANKLIN_REGULAR,
    color: DANGER_COLOR_50,
    fontSize: rem(11),
  },
})

export default styles

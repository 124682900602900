/* @flow */
/* eslint indent: 0 */

import axios from 'axios'
import { navigate } from 'gatsby'
import { css, merge } from 'glamor'
import * as React from 'react'
import type { Location } from 'react-router'

import { Button, Image, Link, Paragraph, TextInput, Title, Wrapper } from '../../components'
import { DefaultLayout as Layout } from './../../layouts'

import demonstrationStyles from '../DemonstrationForm/styles'
import styles from './styles'

import constants from '../../constants'

const { group, input, inputWithMargin, button, terms } = demonstrationStyles
const { FIRST_NAME, LAST_NAME, COMPANY_NAME, EMAIL, PHONE } = constants.form
const { PIPEDRIVE } = constants.lambda

type Props = {
  location: Location,
  url: string,
  contentType: string,
  pageContext: {
    baseUrl: string,
    form: {
      uid: string,
      id: string,
      data: {
        form_title: {
          text: string,
        },
        form_file: {
          url: string,
        },
        meta_description: {
          text: string,
        },
      },
    },
  },
}

type Values = {
  [name: string]: ?string,
}

type Errors = {
  [name: string]: ?string,
}

type State = {|
  values: Values,
  errors: Errors,
  submitCount: number,
|}

export default class FormTemplate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const values = {
      [FIRST_NAME]: '',
      [LAST_NAME]: '',
      [COMPANY_NAME]: '',
      [EMAIL]: '',
      [PHONE]: '',
    }
    const errors = this.validateFields(values)
    const submitCount = 0

    this.state = { values, errors, submitCount }
  }
  static defaultProps = {
    contentType: 'form',
  }

  onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()
      const { errors } = this.state
      if (this.hasErrors(errors)) {
        throw Error('fieldError')
      }
      const formName = this.props.pageContext.form.data.form_title.text
      // TODO: check when this is being used
      await axios({
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        url: PIPEDRIVE,
        data: JSON.stringify({
          [FIRST_NAME]: this.state.values[FIRST_NAME],
          [LAST_NAME]: this.state.values[LAST_NAME],
          [COMPANY_NAME]: this.state.values[COMPANY_NAME],
          [EMAIL]: this.state.values[EMAIL],
          [PHONE]: this.state.values[PHONE],
          source: `Wino.fr - ${formName}: ${this.state.values[FIRST_NAME]} ${
            this.state.values[LAST_NAME]
          }`,
        }),
      })
      navigate('/merci-telechargement', {
        state: {
          dlUrl: this.props.pageContext.form.data.form_file.url,
          formName: this.props.pageContext.form.data.form_title.text,
          suggestions: this.props.location.state.suggestions,
        },
      })
    } catch (err) {
      const msg = `Context: /telechargement (à la validation du formulaire)\n${err}`
      if (err.message != 'fieldError') {
        throw new Error(msg)
      }
    } finally {
      this.setState(prevState => ({
        submitCount: prevState.submitCount + 1,
      }))
    }
  }

  validateField(name: string, value: ?string) {
    switch (name) {
      case FIRST_NAME:
      case LAST_NAME:
      case COMPANY_NAME:
      case PHONE:
        return (!value || String(value).length === 0) && 'Champs requis'
      case EMAIL:
        if (!value || String(value).length === 0) {
          return 'Champs requis'
        }
        return String(value).match(/.+\@.+\..+/) === null && 'Email non valide'
      default:
        return null
    }
  }

  validateFields(values: Values) {
    return Object.keys(values).reduce(
      (acc, key) => ({
        ...acc,
        [key]: this.validateField(key, values[key]),
      }),
      {},
    )
  }

  onChangeField = (name: string, value: string) => {
    this.setState(prevState => {
      return {
        values: {
          ...prevState.values,
          [name]: value || '',
        },
        errors: {
          ...prevState.errors,
          [name]: this.validateField(name, value),
        },
      }
    })
  }

  hasErrors(errors: Errors) {
    return Object.values(errors).some(error => typeof error === 'string' && error.length)
  }

  render() {
    const { contentType, pageContext, location } = this.props
    const { values, submitCount, errors } = this.state
    const { baseUrl, form } = pageContext
    let url = location.href
    if (!url) {
      url = `${baseUrl.slice(0, -1)}${location.pathname}`
    }

    return (
      <Layout
        url={url}
        contentType={contentType}
        name={form.data.form_title.text}
        title={form.data.form_title.text}
        description={form.data.meta_description.text}
        contentImage={'post.data.thumbnail1.Wino_thumbnail.url'}
        enableMinimalistNav={true}
        disableInbound={true}
      >
        <Wrapper>
          <div className={css(styles.container)}>
            <Title className={css(styles.title)}>{form.data.form_title.text}</Title>
            <form className={css(styles.form)} onSubmit={this.onSubmit}>
              <Image
                src="abstractbackground_form_left.png"
                className={merge(css(styles.image), css(styles.imageLeft))}
              />
              <Image
                src="abstractbackground_form_right.png"
                className={merge(css(styles.image), css(styles.imageRight))}
              />
              <div className={css(group)}>
                <TextInput
                  className={css(input, inputWithMargin)}
                  size="large"
                  label="Prénom*"
                  name={FIRST_NAME}
                  value={values[FIRST_NAME]}
                  error={Boolean(submitCount) && errors[FIRST_NAME]}
                  onChange={this.onChangeField}
                />
                <TextInput
                  className={css(input)}
                  size="large"
                  label="Nom*"
                  name={LAST_NAME}
                  value={values[LAST_NAME]}
                  error={Boolean(submitCount) && errors[LAST_NAME]}
                  onChange={this.onChangeField}
                />
              </div>
              <TextInput
                className={css(input)}
                size="large"
                label="Nom de l'entreprise*"
                name={COMPANY_NAME}
                value={values[COMPANY_NAME]}
                error={Boolean(submitCount) && errors[COMPANY_NAME]}
                onChange={this.onChangeField}
              />
              <div className={css(group)}>
                <TextInput
                  className={css(input, inputWithMargin)}
                  size="large"
                  label="Email*"
                  name={EMAIL}
                  value={values[EMAIL]}
                  error={Boolean(submitCount) && errors[EMAIL]}
                  onChange={this.onChangeField}
                />
                <TextInput
                  className={css(input)}
                  size="large"
                  label="Téléphone*"
                  name={PHONE}
                  value={values[PHONE]}
                  error={Boolean(submitCount) && errors[PHONE]}
                  onChange={this.onChangeField}
                />
              </div>
              <Paragraph className={css(terms)} size="tiny">
                Wino requière vos coordonnées pour vous contacter au sujet de ses produits et
                services. Pour en savoir plus le désabonnement aux communications, les pratiques de
                confidentialité et l’engagement en matière de vie privée, consultez la{' '}
                <Link size="tiny" to="">
                  Politique de confidentialité
                </Link>{' '}
                et les{' '}
                <Link size="tiny" to="/conditions-generales-dutilisation">
                  Conditions générales d’utilisation.
                </Link>
              </Paragraph>
              <Button
                disabled={this.hasErrors(errors)}
                size="large"
                appearance="light"
                className={merge(css(button), styles.button)}
              >
                Télécharger maintenant !
              </Button>
            </form>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}
